"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_core_1 = require("@vue/runtime-core");
var vue_router_1 = require("vue-router");
var locations_1 = require("../composables/locations");
var items_1 = require("../composables/items");
var handleError_1 = require("../utils/handleError");
var functions_1 = require("../utils/functions");
exports.default = {
    setup: function () {
        var router = vue_router_1.useRouter();
        var showActive = runtime_core_1.ref(true);
        var showInactive = runtime_core_1.ref(false);
        ///
        var _a = locations_1.default(), locations = _a.locations, loadLocations = _a.load, refreshLocations = _a.refreshLocations;
        refreshLocations();
        loadLocations()
            .then(function () { return refreshLocations(); })
            .catch(handleError_1.default);
        ///
        var _b = items_1.default(), locationItems = _b.locationItems, loadLocationItems = _b.loadLocationItems, refreshLocationItems = _b.refreshLocationItems;
        var locationsFiltered = runtime_core_1.computed(function () {
            return locations.data.filter(function (location) {
                return (showActive.value && !location.archived_at) ||
                    (showInactive.value && location.archived_at);
            });
        });
        runtime_core_1.watch(locations, function () {
            locations.data
                .map(function (location) { return location.id; })
                .forEach(function (locationID) {
                if (!refreshLocationItems(locationID))
                    loadLocationItems(locationID)
                        .then(function () { return refreshLocationItems(locationID); })
                        .catch(handleError_1.default);
            });
        });
        ///
        var goToLocationItems = function (id) {
            router.push({ name: 'locations.items', params: { id: id } });
        };
        return {
            locations: locations,
            locationsFiltered: locationsFiltered,
            locationItems: locationItems,
            goToLocationItems: goToLocationItems,
            toEUR: functions_1.toEUR,
            showActive: showActive,
            showInactive: showInactive,
        };
    },
};
