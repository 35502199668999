"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = {
    props: {
        locations: { type: Object },
        order: { type: Object, default: null },
        advanced: Boolean,
        locationDialog: Boolean,
        confirmButton: String,
        title: String,
    },
    emits: ['confirm', 'update:locationDialog'],
    setup: function () {
        return {
            action: vue_1.ref(true),
        };
    },
};
