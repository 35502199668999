<template>
    <el-tag size="small" type="success" v-if="!!order.delivered_at">
        Geliefert
    </el-tag>

    <el-tag size="small" type="primary" v-else-if="!!order.loaded_at">
        Beladen
    </el-tag>

    <el-tag size="small" type="warning" v-else> Ausstehend </el-tag>
</template>

<script>
export default {
    props: { order: { type: Object } },
};
</script>

<style></style>
