<template>
    <div class="container">
        <el-menu default-active="" mode="horizontal" @select="handleSelect">
            <!-- <el-menu-item index="home">Home</el-menu-item> -->
            <el-menu-item index="orders">Aufträge</el-menu-item>
            <el-menu-item index="items">Artikel</el-menu-item>
            <el-menu-item
                index="locations"
                v-if="user && user.permissions.viewLocationsItems"
            >
                Orte
            </el-menu-item>
            <el-menu-item
                index="locations.overview"
                v-if="user && user.permissions.viewLocationsItems"
            >
                Übersicht
            </el-menu-item>
            <el-menu-item index="user" v-if="user" style="float: right">
                {{ user.displayName }}
            </el-menu-item>
        </el-menu>
        <router-view class="mt-4 p-4" />
        <footer>
            <button
                onclick="window.location.reload(true)"
                class="w-full text-center p-2 text-sm text-gray-500 border-t-2"
            >
                <div>Version: {{ VERSION }}</div>
                <div>
                    {{ new Date(TIME).toLocaleString() }}
                </div>
            </button>
        </footer>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import useUser from './composables/users';
import handleError from './utils/handleError';

export default {
    name: 'App',
    setup() {
        const router = useRouter();
        const { user, loadUser, refreshUser } = useUser();

        if (!refreshUser()) {
            console.log('loadUsers');
            loadUser()
                .then(() => refreshUser() && console.log(user))
                .catch(handleError);
        }

        console.log(user);

        const handleSelect = (key, keyPath) => {
            console.log(key, keyPath);
            router.push({ name: key });
        };

        return { user, handleSelect, VERSION, TIME };
    },
};
</script>

<style>
.container {
    width: 100%;
    max-width: 100%;
    position: relative;
}

.el-input-number--small {
    width: 110px;
}
</style>
