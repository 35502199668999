<template>
    <div class="ml-auto">
        <el-button type="primary" @click="createOrderDialog = true">
            Neu
        </el-button>
        <location-dialog
            :locations="locations"
            :advanced="advanced"
            v-model:locationDialog="createOrderDialog"
            @confirm="() => $emit('createOrder')"
            title="Auftrag erstellen"
            confirmButton="Erstellen"
        ></location-dialog>
    </div>
</template>

<script>
import { ref } from 'vue';
import LocationDialog from './LocationDialog.vue';

export default {
    components: { LocationDialog },
    props: { locations: { type: Object }, advanced: Boolean },
    emits: ['createOrder'],
    setup() {
        return {
            createOrderDialog: ref(false),
            action: ref(true),
        };
    },
};
</script>

<style></style>
