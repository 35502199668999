<template>
    <el-select
        v-model="modelValue"
        placeholder="Kategorie"
        size="small"
        v-if="categories.data && categories.data.length"
        @change="$emit('update:modelValue', modelValue)"
    >
        <el-option
            v-for="(cat, index) in categories.data"
            :key="cat.id"
            :label="
                (['all', 'other'].includes(cat.id)
                    ? ''
                    : `${padNumber(
                          index,
                          categories.data.filter(
                              (c) => !['all', 'others'].includes(c.id)
                          ).length
                      )}. `) + cat.name
            "
            :value="cat.id"
        >
        </el-option>
    </el-select>
</template>

<script>
import { reactive } from '@vue/reactivity';
import handleError from '../utils/handleError';
import { padNumber } from '../utils/functions';

export default {
    props: {
        modelValue: String,
    },
    emits: ['update:modelValue'],
    setup() {
        const categories = reactive({ data: [] });

        axios
            .get('/api/categories')
            .then((res) => {
                categories.data = res.data;
                categories.data.unshift({
                    id: 'all',
                    name: 'Alle',
                });
                categories.data.push({
                    id: 'other',
                    name: 'Sonstige',
                });
                console.log({ categories: categories.data });
            })
            .catch(handleError);

        return { categories, padNumber };
    },
};
</script>

<style></style>
