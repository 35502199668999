<template>
    <order-status :order="order" class="mb-3"></order-status>
    <el-descriptions :column="1">
        <!-- <el-descriptions-item label="Status">
        </el-descriptions-item> -->
        <span
            v-if="
                (order.scheduled_at && order.scheduled_at.length > 0) ||
                alwaysShowSchedule
            "
        >
            <el-descriptions-item
                label="Termin"
                class-name="font-bold"
                label-class-name="font-bold"
            >
                <el-tooltip
                    placement="top"
                    effect="light"
                    v-if="order.scheduled_at && order.scheduled_at.length > 0"
                >
                    <template #content>
                        <p
                            v-for="scheduled_at of order.scheduled_at"
                            :key="scheduled_at"
                            class="my-1"
                        >
                            {{
                                new Date(
                                    scheduled_at.created_at
                                ).toLocaleString()
                            }}
                            {{ scheduled_at.created_by.displayName }}:
                            {{
                                new Date(
                                    scheduled_at.scheduled_at
                                ).toLocaleString(language, dateOptions)
                            }}
                            <br />
                        </p>
                    </template>
                    <span>
                        {{
                            new Date(
                                order.scheduled_at[
                                    order.scheduled_at.length - 1
                                ].scheduled_at
                            ).toLocaleString(language, dateOptions)
                        }}
                    </span>
                </el-tooltip>

                <slot name="schedule"></slot>
            </el-descriptions-item>
        </span>

        <span
            v-if="
                (order.transport_items && order.transport_items.length > 0) ||
                alwaysShowTransport
            "
        >
            <el-descriptions-item>
                <template #label>
                    <el-tooltip
                        placement="top"
                        effect="light"
                        v-if="
                            order.transport_items &&
                            order.transport_items.length > 0
                        "
                    >
                        <template #content>
                            <p
                                v-for="transport_item of order.transport_items.filter(
                                    (tItem) => tItem.quantity !== 0
                                )"
                                :key="transport_item"
                                class="my-1"
                            >
                                {{ transport_item.quantity }}x
                                {{ transport_item.description }}
                                ({{ transport_item.number }})
                            </p>

                            <p
                                v-if="
                                    order.transport_items.filter(
                                        (tItem) => tItem.quantity !== 0
                                    ).length === 0
                                "
                            >
                                Keine Artikel
                            </p>

                            <p v-if="order.transport_items_updated_at">
                                <br />
                                {{
                                    new Date(
                                        order.transport_items_updated_at
                                    ).toLocaleString()
                                }}
                                {{
                                    order.transport_items_updated_by.displayName
                                }}
                            </p>
                        </template>
                        <span>
                            <strong> Transport </strong>
                            <span v-if="order.transport_items_to !== null">
                                ({{
                                    order.transport_items_to
                                        ? 'Anlieferung'
                                        : 'Abholung'
                                }})
                            </span>
                        </span>
                    </el-tooltip>
                </template>
                {{
                    order.transport_items
                        .filter((tItem) => tItem.quantity !== 0)
                        .map(
                            (tItem) =>
                                `${tItem.quantity}x&nbsp;${tItem.description}`
                        )
                        .join(', ')
                }}
                <slot name="transport"></slot>
            </el-descriptions-item>
        </span>

        <el-descriptions-item label="Nr.">
            {{ order.id }}
        </el-descriptions-item>
        <el-descriptions-item label="Artikel">
            {{ order.items_count }}
        </el-descriptions-item>

        <span v-if="showLocation">
            <div
                v-if="
                    new Object(order.from).type === 'job' &&
                    order.to.type === 'storage'
                "
            >
                <el-descriptions-item label="Projekt">
                    {{ new Object(order.from).name }}
                </el-descriptions-item>
                <el-descriptions-item label="Aktion">
                    Abholung
                </el-descriptions-item>
            </div>

            <div
                v-else-if="
                    new Object(order.from).type === 'storage' &&
                    order.to.type === 'job'
                "
            >
                <el-descriptions-item label="Projekt">
                    {{ new Object(order.to).name }}
                </el-descriptions-item>
                <el-descriptions-item label="Aktion">
                    Anlieferung
                </el-descriptions-item>
            </div>

            <div v-else>
                <el-descriptions-item label="Von">
                    {{ new Object(order.from).name }}
                </el-descriptions-item>
                <el-descriptions-item label="Nach">
                    {{ new Object(order.to).name }}
                </el-descriptions-item>
            </div>
        </span>

        <!-- v-if does logically does not need to exist but else render error created_by not defined -->
        <el-descriptions-item label="Erstellt" v-if="order.created_at">
            {{ new Date(order.created_at).toLocaleString() }}
            {{ order.created_by.displayName }}
        </el-descriptions-item>
        <el-descriptions-item
            label="Bearbeitet"
            v-if="
                order.created_by.id !== order.updated_by.id ||
                new Date(order.updated_at) - new Date(order.created_at) >
                    1000 * 60 * 5
            "
        >
            {{ new Date(order.updated_at).toLocaleString() }}
            {{ order.updated_by.displayName }}
        </el-descriptions-item>
        <el-descriptions-item label="Beladen" v-if="order.loaded_at">
            {{ new Date(order.loaded_at).toLocaleString() }}
            {{ order.loaded_by.displayName }}
        </el-descriptions-item>
        <el-descriptions-item label="Geliefert" v-if="order.delivered_at">
            {{ new Date(order.delivered_at).toLocaleString() }}
            {{ order.delivered_by.displayName }}
        </el-descriptions-item>
        <el-descriptions-item label="Notiz" v-if="alwaysShowNote || order.note">
            {{ order.note }}
            <slot name="note"></slot>
        </el-descriptions-item>
    </el-descriptions>
</template>

<script>
import { ref } from 'vue';

import OrderStatus from './OrderStatus.vue';

export default {
    components: {
        OrderStatus,
    },
    setup(props, { attrs, slots, emit }) {
        return {
            language: navigator.language || 'de',
            dateOptions: window.dateOptions,
            alwaysShowSchedule: !!slots.schedule,
            alwaysShowTransport: !!slots.transport,
            alwaysShowNote: !!slots.note,
        };
    },
    props: {
        order: { type: Object },
        showLocation: { type: Boolean, default: true },
    },
};
</script>

<style></style>
