"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var vue_1 = require("vue");
var element_plus_1 = require("element-plus");
var users_1 = require("../composables/users");
var handleError_1 = require("../utils/handleError");
exports.default = {
    setup: function () {
        var user = users_1.default().user;
        var passwordChangeForm = vue_1.reactive({
            oldPassword: '',
            password: '',
            rules: {
                oldPassword: [
                    {
                        required: true,
                        message: 'Bitte gebe das alte Passwort an',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Bitte gebe ein neues Passwort an',
                        trigger: 'blur',
                    },
                ],
            },
        });
        var onSubmitPasswordChangeForm = function () {
            axios_1.default
                .patch("/api/users/" + user.value.id, {
                oldPassword: passwordChangeForm.oldPassword,
                password: passwordChangeForm.password,
            })
                .then(function () {
                element_plus_1.ElMessage({
                    message: 'Passwort geändert',
                    showClose: true,
                    type: 'success',
                });
                passwordChangeForm.oldPassword = '';
                passwordChangeForm.password = '';
            })
                .catch(function (error) {
                if (error.response.status === 403) {
                    element_plus_1.ElMessage({
                        message: 'Passwort inkorrekt',
                        showClose: true,
                        type: 'error',
                    });
                    passwordChangeForm.oldPassword = '';
                }
                else if (error.response.status === 422) {
                    element_plus_1.ElMessage({
                        message: 'Bitte gebe das alte und neue Password an',
                        showClose: true,
                        type: 'error',
                    });
                }
                else {
                    handleError_1.default(error);
                }
            });
        };
        var logout = function () {
            axios_1.default.delete('/api/session');
            window.location.href = window.location.origin + "/login";
        };
        return { passwordChangeForm: passwordChangeForm, onSubmitPasswordChangeForm: onSubmitPasswordChangeForm, logout: logout };
    },
};
