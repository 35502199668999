<template>
    <el-card class="box-card" v-for="item of items" :key="item">
        <template #header>
            <div class="card-header">
                <el-tooltip
                    placement="top"
                    effect="light"
                    :disabled="!item.extra_numbers.length"
                >
                    <template #content>
                        <span
                            v-for="number of item.extra_numbers"
                            :key="number"
                        >
                            {{ number }}<br />
                        </span>
                    </template>
                    <div>
                        {{ item.number }}
                        <span
                            v-if="
                                item.extra_numbers.length ||
                                item.extra_descriptions.length
                            "
                            class="text-xs text-gray-400"
                            >*</span
                        >
                    </div>
                </el-tooltip>
                <div v-if="state === 'edit'">
                    <span
                        class="mr-2"
                        style="font-size: 0.8rem"
                        v-if="
                            item.quantitySelectedBefore !==
                            item.quantitySelected
                        "
                    >
                        ({{ item.quantitySelectedBefore }})
                    </span>
                    <!-- :disabled="!!order.data.loaded_at" -->
                    <el-input-number
                        v-model="item.quantitySelected"
                        step-strictly
                        :disabled="item.loaded"
                        :step="1"
                        :min="0"
                        :max="
                            //item.quantityAvailable !== null
                            false ? item.quantityAvailable : 999999999
                        "
                        size="small"
                        @blur="
                            () => {
                                if (!item.quantitySelected)
                                    item.quantitySelected = 0;
                            }
                        "
                        @change="$emit('change')"
                    ></el-input-number>
                </div>
                <div v-else-if="state == 'check'">
                    <strong v-if="item.checked !== item.loaded" class="mr-2">
                        *
                    </strong>
                    <el-checkbox v-model="item.checked"
                        >{{ item.quantitySelected }}
                    </el-checkbox>
                </div>
                <div v-else-if="state === 'view'" class="text-sm">
                    {{ item.quantitySelected }}
                </div>
                <slot :item="item"></slot>
            </div>
        </template>
        <div class="flex">
            <div class="h-16 w-20 mr-2">
                <!-- v-if="item.img" -->
                <!-- lazy
                    scroll-container="body" -->
                <el-image
                    :src="`/images/items/small/${item.img}`"
                    :preview-src-list="[`/images/items/original/${item.img}`]"
                    hide-on-click-modal
                >
                    <template #error>
                        <span class="text-xs"> Kein Bild </span>
                    </template>
                </el-image>
            </div>
            <div>
                <el-tooltip
                    placement="top-start"
                    effect="light"
                    :disabled="!item.extra_descriptions.length"
                >
                    <template #content>
                        <span
                            v-for="description of item.extra_descriptions"
                            :key="description"
                        >
                            {{ description }}<br />
                        </span>
                    </template>
                    <div class="text item">
                        {{ item.description }}
                    </div>
                </el-tooltip>
                <div
                    class="text item"
                    v-if="
                        item.quantityAvailable !== null &&
                        state === 'edit' &&
                        (order.from.type === 'storage' ||
                            user.permissions.viewLocationsItems)
                    "
                >
                    <strong>Verfügbar:</strong>
                    {{ item.quantityAvailable - item.quantitySelected }}
                </div>
            </div>
        </div>
    </el-card>
</template>

<script>
import useUsers from '../composables/users';

export default {
    props: {
        items: { type: Object },
        state: { type: String },
        order: { type: Object },
    },
    emits: ['change'],
    setup() {
        const { user } = useUsers();

        return { user };
    },
};
</script>

<style scoped>
.el-input-number--small {
    width: 110px;
    color: red;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 0.8em;
}

.item {
    margin-bottom: 0.3rem;
}

.box-card {
    width: 100%;
    max-width: 720px;
    box-sizing: border-box;
    margin-bottom: 0.3rem;
}
</style>
