"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var reactivity_1 = require("@vue/reactivity");
var items_1 = require("../composables/items");
var locations_1 = require("../composables/locations");
var handleError_1 = require("../utils/handleError");
var functions_1 = require("../utils/functions");
exports.default = {
    setup: function () {
        var route = vue_router_1.useRoute();
        var locationID = route.params.id.toString();
        ///
        var _a = items_1.default(), locationItems = _a.locationItems, loadLocationItems = _a.loadLocationItems, refreshLocationItems = _a.refreshLocationItems;
        refreshLocationItems(locationID);
        loadLocationItems(locationID)
            .then(function (success) { return success && refreshLocationItems(locationID); })
            .catch(handleError_1.default);
        var items = reactivity_1.computed(function () {
            return locationItems[locationID].data;
        });
        var totalValue = reactivity_1.computed(function () {
            return functions_1.toEUR(items.value.reduce(function (acc, item) {
                return acc + item.totalValue;
            }, 0));
        });
        ///
        var _b = locations_1.default(), locations = _b.locations, loadLocations = _b.load, refreshLocations = _b.refreshLocations;
        refreshLocations();
        loadLocations()
            .then(function () { return refreshLocations(); })
            .catch(handleError_1.default);
        var location = reactivity_1.computed(function () {
            return locations.data.find(function (location) { return location.id === locationID; });
        });
        ///
        var currencyFormatter = function (row, colum, cell) { return functions_1.toEUR(cell); };
        var summary = function (param) {
            var columns = param.columns, data = param.data;
            var sums = [];
            columns.forEach(function (column, index) {
                if (index < 4) {
                    sums[index] = index === 3 ? 'Summe' : '';
                    return;
                }
                if (index % 2 === 1) {
                    if (data.length === 0 ||
                        data.some(function (item) { return isNaN(item === null || item === void 0 ? void 0 : item.totalValue); }))
                        return;
                    sums[index] = functions_1.toEUR(data.reduce(function (acc, item) { return acc + item.totalValue; }, 0));
                }
            });
            return sums;
        };
        return { items: items, location: location, totalValue: totalValue, currencyFormatter: currencyFormatter, summary: summary };
    },
};
