"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('./bootstrap');
var vue_1 = require("vue");
var App_vue_1 = require("./vue/App.vue");
var element_plus_1 = require("element-plus");
var router_1 = require("./vue/router");
require("../css/app.css");
require("element-plus/lib/theme-chalk/index.css");
var app = vue_1.createApp(App_vue_1.default);
app.use(element_plus_1.default);
app.use(router_1.default);
window.router = router_1.default;
app.mount('#app');
