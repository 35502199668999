"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var element_plus_1 = require("element-plus");
function handleError(error) {
    var _a, _b;
    if (!error.response)
        throw error;
    element_plus_1.ElMessage({
        message: "\n            " + error.message + " " + error.response.statusText + "             url: " + error.response.config.url + ",            method: " + error.response.config.method + ",            message: " + ((_a = error.response.data) === null || _a === void 0 ? void 0 : _a.message) + ",            error: " + JSON.stringify((_b = error.response.data) === null || _b === void 0 ? void 0 : _b.errors) + "            at " + location.href + "\n        ",
        type: 'error',
        duration: -1,
        showClose: true,
    });
}
exports.default = handleError;
