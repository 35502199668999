"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_core_1 = require("@vue/runtime-core");
var vue_router_1 = require("vue-router");
var locations_1 = require("../composables/locations");
var items_1 = require("../composables/items");
var handleError_1 = require("../utils/handleError");
var functions_1 = require("../utils/functions");
exports.default = {
    setup: function () {
        var router = vue_router_1.useRouter();
        ///
        var _a = locations_1.default(), locations = _a.locations, loadLocations = _a.load, refreshLocations = _a.refreshLocations;
        refreshLocations();
        loadLocations()
            .then(function () { return refreshLocations(); })
            .catch(handleError_1.default);
        ///
        var _b = items_1.default(), locationItems = _b.locationItems, loadLocationItems = _b.loadLocationItems, refreshLocationItems = _b.refreshLocationItems;
        runtime_core_1.watch(locations, function () {
            console.log('Locations: ', locations);
            locations.data
                .map(function (location) { return location.id; })
                .reverse()
                .forEach(function (locationID) {
                if (!refreshLocationItems(locationID))
                    loadLocationItems(locationID)
                        .then(function () { return refreshLocationItems(locationID); })
                        .catch(handleError_1.default);
            });
            console.log('items ', locationItems);
        });
        ///
        var goToLocationItems = function (id) {
            router.push({ name: 'locations.items', params: { id: id } });
        };
        var currencyFormatter = function (row, colum, cell) { return functions_1.toEUR(cell); };
        var summary = function (param) {
            var columns = param.columns, data = param.data;
            var sums = [];
            columns.forEach(function (column, index) {
                var _a;
                if (index < 4) {
                    sums[index] = index === 3 ? 'Summe' : '';
                    return;
                }
                if (index % 2 === 1) {
                    console.log(column === null || column === void 0 ? void 0 : column.columnKey);
                    console.log((_a = data[index]) === null || _a === void 0 ? void 0 : _a.id);
                    if (isNaN(parseInt(column === null || column === void 0 ? void 0 : column.columnKey))) {
                        return;
                    }
                    sums[index] = functions_1.toEUR(locationItems[parseInt(column.columnKey)].data.reduce(function (acc, item) { return acc + item.totalValue; }, 0));
                }
            });
            return sums;
        };
        return {
            locations: locations,
            locationItems: locationItems,
            goToLocationItems: goToLocationItems,
            toEUR: functions_1.toEUR,
            currencyFormatter: currencyFormatter,
            summary: summary,
        };
    },
};
