"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var vue_router_1 = require("vue-router");
var reactivity_1 = require("@vue/reactivity");
var functions_1 = require("../utils/functions");
var handleError_1 = require("../utils/handleError");
var ItemView_vue_1 = require("../components/ItemView.vue");
var router_1 = require("../router");
exports.default = {
    components: { ItemView: ItemView_vue_1.default },
    setup: function () {
        var route = vue_router_1.useRoute();
        var itemID = route.params.id.toString();
        ///
        var item = reactivity_1.ref();
        var itemLocations = reactivity_1.ref([]);
        Promise.all([
            axios_1.default.get("/api/items/" + itemID),
            axios_1.default.get("/api/items/" + itemID + "/locations"),
        ])
            .then(function (res) {
            item.value = res[0].data;
            console.log(res[0].data);
            itemLocations.value = res[1].data;
            itemLocations.value = __spreadArray([
                {
                    id: '',
                    name: 'Baustellen',
                    type: '',
                    quantity: itemLocations.value
                        .filter(function (location) {
                        return (location.type === 'job' &&
                            location.quantity > 0);
                    })
                        .map(function (location) { return location.quantity; })
                        .reduce(function (previous, current) { return previous + current; }),
                }
            ], itemLocations.value).map(function (location) {
                return Object.assign(location, {
                    value: location.quantity * item.value.value,
                });
            });
        })
            .catch(handleError_1.default);
        var currencyFormatter = function (row, colum, cell) { return functions_1.toEUR(cell); };
        var goToLocationItems = function (id) {
            router_1.default.push({ name: 'locations.items', params: { id: id } });
        };
        return {
            item: item,
            itemLocations: itemLocations,
            toEUR: functions_1.toEUR,
            currencyFormatter: currencyFormatter,
            goToLocationItems: goToLocationItems,
        };
    },
};
