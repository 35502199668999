"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var element_plus_1 = require("element-plus");
var orders_1 = require("../composables/orders");
var locations_1 = require("../composables/locations");
var transportItems_1 = require("../composables/transportItems");
var handleError_1 = require("../utils/handleError");
var users_1 = require("../composables/users");
var icons_1 = require("@element-plus/icons");
var OrderStatus_vue_1 = require("../components/OrderStatus.vue");
var OrderDescription_vue_1 = require("../components/OrderDescription.vue");
var CreateOrder_vue_1 = require("../components/CreateOrder.vue");
var LocationDialog_vue_1 = require("../components/LocationDialog.vue");
var axios_1 = require("axios");
exports.default = vue_1.defineComponent({
    name: 'ViewOrders',
    components: {
        Edit: icons_1.Edit,
        Delete: icons_1.Delete,
        MoreFilled: icons_1.MoreFilled,
        OrderStatus: OrderStatus_vue_1.default,
        OrderDescription: OrderDescription_vue_1.default,
        CreateOrder: CreateOrder_vue_1.default,
        LocationDialog: LocationDialog_vue_1.default,
    },
    setup: function () {
        var router = vue_router_1.useRouter();
        var loading = vue_1.ref(false);
        var exceptDelivered = vue_1.ref(true);
        var orderBySchedule = vue_1.ref(JSON.parse(localStorage.getItem('orderBySchedule')));
        var _a = orders_1.default(), orderList = _a.orderList, loadOrderList = _a.loadOrderList, saveOrder = _a.saveOrder, deleteOrder = _a.deleteOrder, refreshOrderList = _a.refreshOrderList;
        loadOrderList()
            .then(function () {
            console.log('orders loaded');
            refreshOrderList();
            loading.value = false;
        })
            .catch(handleError_1.default);
        refreshOrderList();
        var _b = transportItems_1.default(), transportItems = _b.transportItems, loadTransportItems = _b.loadTransportItems, refreshTransportItems = _b.refreshTransportItems;
        loadTransportItems()
            .then(function () {
            refreshTransportItems();
            console.log(transportItems);
        })
            .catch(handleError_1.default);
        var orderListFiltered = vue_1.computed(function () {
            return orderList.data
                .map(function (order) {
                return Object.assign(order, {
                    transport_items: order.transport_items.map(function (tItem) {
                        var item = transportItems.data.find(function (i) { return i.id === tItem.id; });
                        if (!item)
                            return tItem;
                        return {
                            number: item === null || item === void 0 ? void 0 : item.number,
                            description: item === null || item === void 0 ? void 0 : item.description,
                            quantity: tItem.quantity,
                        };
                    }),
                });
            })
                .filter(function (order) { return !exceptDelivered.value || !order.delivered_at; })
                .sort(function (a, b) {
                var _a, _b;
                if (orderBySchedule.value) {
                    if (a.scheduled_at.length === 0)
                        return -1;
                    if (b.scheduled_at.length === 0)
                        return 1;
                    if (((_a = a.scheduled_at[a.scheduled_at.length - 1]) === null || _a === void 0 ? void 0 : _a.scheduled_at) >
                        ((_b = b.scheduled_at[b.scheduled_at.length - 1]) === null || _b === void 0 ? void 0 : _b.scheduled_at)) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                }
                else {
                    return 0;
                }
            });
        });
        var createOrder = function () {
            saveOrder(locations.state.from, locations.state.to)
                .then(function () {
                locations.state.from = '';
                locations.state.to = '';
                locations.state.project = '';
                return loadOrderList();
            })
                .then(function () { return refreshOrderList(); })
                .catch(handleError_1.default);
        };
        ///
        var _c = locations_1.default(), locations = _c.locations, loadLocations = _c.load, refreshLocations = _c.refreshLocations;
        loadLocations()
            .then(function () {
            refreshLocations();
            console.log('locations loaded');
        })
            .catch(handleError_1.default);
        refreshLocations();
        ///
        var user = users_1.default().user;
        ///
        var onButton = {
            edit: function (id) {
                router.push({ name: 'orders.edit', params: { id: id } });
            },
            delete: function (id) {
                var _a;
                var order = orderList.data.find(function (order) { return order.id === id; });
                element_plus_1.ElMessageBox.confirm("Bestellung " + id + " (" + (((_a = order.project) === null || _a === void 0 ? void 0 : _a.name) ||
                    order.from.name + " > " + order.to.name) + ")", 'Löschen', {
                    confirmButtonText: 'Löschen',
                    cancelButtonText: 'Abbrechen',
                    type: 'warning',
                })
                    .then(function () { return deleteOrder(id); })
                    .then(function () {
                    var _a;
                    refreshOrderList();
                    element_plus_1.ElMessage({
                        type: 'success',
                        message: "Bestellung " + id + " (" + (((_a = order.project) === null || _a === void 0 ? void 0 : _a.name) || order.from.name) + ") gel\u00F6scht.",
                    });
                })
                    .catch(handleError_1.default)
                    .catch(function () { });
            },
            changeStatus: function (id, status) {
                var _a;
                var order = orderList.data.find(function (order) { return order.id === id; });
                element_plus_1.ElMessageBox.confirm("Bestellung " + id + " (" + (((_a = order.project) === null || _a === void 0 ? void 0 : _a.name) ||
                    order.from.name + " > " + order.to.name) + ")", "Status auf " + ['Ausstehend', 'Beladen', 'Geliefert'][status] + " setzen", {
                    confirmButtonText: 'Ändern',
                    cancelButtonText: 'Abbrechen',
                    type: 'warning',
                })
                    .then(function () {
                    return axios_1.default.patch("/api/orders/" + id, {
                        loaded: status === 1,
                        delivered: status === 2,
                    });
                })
                    .then(function () { return loadOrderList(); })
                    .then(function () {
                    var _a;
                    refreshOrderList();
                    element_plus_1.ElMessage({
                        type: 'success',
                        message: "Status f\u00FCr " + id + " (" + (((_a = order.project) === null || _a === void 0 ? void 0 : _a.name) || order.from.name) + ") auf " + ['Ausstehend', 'Beladen', 'Geliefert'][status] + " gesetzt.",
                    });
                })
                    .catch(handleError_1.default);
            },
            changeOrder: function (id) {
                axios_1.default
                    .patch("/api/orders/" + id, {
                    from: locations.state.from,
                    to: locations.state.to,
                })
                    .then(function () {
                    loadOrderList();
                })
                    .then(function () {
                    var _a;
                    refreshOrderList();
                    var order = orderList.data.find(function (order) { return order.id === id; });
                    element_plus_1.ElMessage({
                        type: 'success',
                        message: "Orte f\u00FCr " + id + " auf (" + (((_a = order.project) === null || _a === void 0 ? void 0 : _a.name) || order.from.name) + ") ge\u00E4ndert.",
                    });
                })
                    .catch(handleError_1.default);
            },
        };
        ///
        vue_1.watch(orderBySchedule, function () {
            localStorage.setItem('orderBySchedule', orderBySchedule.value);
        });
        ///
        return {
            orderListFiltered: orderListFiltered,
            onButton: onButton,
            loading: loading,
            exceptDelivered: exceptDelivered,
            orderBySchedule: orderBySchedule,
            createOrder: createOrder,
            locations: locations,
            user: user,
            locationDialog: vue_1.ref(false),
            console: console,
            orderSelected: {},
        };
    },
});
