"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var items_1 = require("../composables/items");
var orders_1 = require("../composables/orders");
var orderItems_1 = require("../composables/orderItems");
var transportItems_1 = require("../composables/transportItems");
var orderTransportItems_1 = require("../composables/orderTransportItems");
var users_1 = require("../composables/users");
var handleError_1 = require("../utils/handleError");
var functions_1 = require("../utils/functions");
var groups_1 = require("../composables/groups");
var icons_1 = require("@element-plus/icons");
var ItemView_vue_1 = require("../components/ItemView.vue");
var OrderDescription_vue_1 = require("../components/OrderDescription.vue");
var CategorySelect_vue_1 = require("../components/CategorySelect.vue");
var GroupSelect_vue_1 = require("../components/GroupSelect.vue");
var element_plus_1 = require("element-plus");
exports.default = {
    components: {
        Edit: icons_1.Edit,
        ItemView: ItemView_vue_1.default,
        OrderDescription: OrderDescription_vue_1.default,
        CategorySelect: CategorySelect_vue_1.default,
        GroupSelect: GroupSelect_vue_1.default,
    },
    setup: function () {
        var _a, _b, _c;
        var router = vue_router_1.useRouter();
        var route = vue_router_1.useRoute();
        var language = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.language) !== null && _a !== void 0 ? _a : 'de';
        var orderID = parseInt(route.params.id.toString());
        var search = vue_1.ref('');
        var transportItemsDialog = vue_1.ref(false);
        vue_1.watch(transportItemsDialog, console.log);
        var noteDialog = vue_1.ref(false);
        var note = vue_1.ref('');
        var state = vue_1.ref('edit');
        var onlySelected = vue_1.ref(false);
        var sortQuantity = vue_1.ref(true);
        var loading = vue_1.ref(true);
        var user = users_1.default().user;
        var _d = items_1.default(), locationItems = _d.locationItems, loadLocationItems = _d.loadLocationItems, refreshLocationItems = _d.refreshLocationItems;
        var _e = orders_1.default(), orders = _e.orders, loadOrder = _e.loadOrder, updateOrder = _e.updateOrder, refreshOrder = _e.refreshOrder;
        var _f = orderItems_1.default(), orderItems = _f.orderItems, loadOrderItems = _f.loadOrderItems, createOrderItem = _f.createOrderItem, updateOrderItem = _f.updateOrderItem, deleteOrderItem = _f.deleteOrderItem, refreshOrderItems = _f.refreshOrderItems;
        window.ElLoading = element_plus_1.ElLoading;
        var category = vue_1.ref('all');
        var group = vue_1.ref('all');
        // onMounted(() => {
        //     ElLoading.service({ target: '.loading' });
        // });
        var order = vue_1.computed(function () {
            console.log('compute order: ', orders[orderID]);
            if (!orders[orderID])
                return null;
            note.value = orders[orderID].note;
            return Object.assign(orders[orderID], {
                transport_items: transportItems.value.map(function (tItem) {
                    return {
                        number: tItem.number,
                        description: tItem.description,
                        // so that on hover the actually saved quantity is shown
                        // TODO consider adding indicator for unsaved changes
                        quantity: tItem.quantitySelectedBefore,
                    };
                }),
                transport_items_to_selected: orders[orderID].transport_items_to,
                transport_items_to_before: orders[orderID].transport_items_to,
            });
        });
        var usedUnsavedItems = false;
        var usedUnsavedItemsDisplayed = false;
        var items = vue_1.computed(function () {
            var _a, _b, _c;
            console.log('compute items...');
            if (!((_b = (_a = order.value) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.id))
                return [];
            console.log(order.value.id, order.value.from.id);
            var unsavedItems = (_c = JSON.parse(localStorage.getItem("unsavedItems" + orderID))) !== null && _c !== void 0 ? _c : [];
            console.log(unsavedItems);
            var items = locationItems[order.value.from.id].data.map(function (item) {
                var _a;
                var orderItem = orderItems[order.value.id].find(function (orderItem) { return orderItem.id === item.id; });
                var orderItemQuantity = (orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) || 0;
                var orderItemLoaded = !!(orderItem === null || orderItem === void 0 ? void 0 : orderItem.loaded);
                var unsavedQuantity = (_a = unsavedItems.find(function (i) { return i.id === item.id; })) === null || _a === void 0 ? void 0 : _a.quantity;
                if (unsavedQuantity !== undefined &&
                    unsavedQuantity !== orderItemQuantity)
                    usedUnsavedItems = true;
                // console.log(unsavedQuantity, item.id, usedUnsavedItems);
                return Object.assign(item, {
                    quantityAvailable: item.quantity === null
                        ? null
                        : item.quantity + orderItemQuantity,
                    quantitySelected: unsavedQuantity !== null && unsavedQuantity !== void 0 ? unsavedQuantity : orderItemQuantity,
                    quantitySelectedBefore: orderItemQuantity,
                    checked: orderItemLoaded,
                    loaded: orderItemLoaded,
                });
            });
            if (usedUnsavedItems && !usedUnsavedItemsDisplayed) {
                console.log({ usedUnsavedItems: usedUnsavedItems });
                // error is thrown without timeout ('oldVNode is undefined')
                setTimeout(function () {
                    element_plus_1.ElMessage({
                        message: 'Nicht gespeicherte Änderungen wurden wiederhergestellt',
                        duration: 2000,
                        type: 'info',
                    });
                }, 0);
                usedUnsavedItemsDisplayed = true;
            }
            return items;
        });
        console.log(items, orderItems);
        var _g = groups_1.default(), groups = _g.groups, loadGroups = _g.loadGroups, refreshGroups = _g.refreshGroups;
        refreshGroups();
        loadGroups()
            .then(function () {
            refreshGroups();
        })
            .catch(handleError_1.default);
        var itemsFiltered = vue_1.computed(function () {
            return items.value
                .filter(function (item) {
                return (functions_1.itemInGroupOrCategory(category.value, group.value, item, groups.data) &&
                    (!onlySelected.value ||
                        item.quantitySelected !== 0) &&
                    functions_1.searchIncludesItem(search.value, item)) ||
                    search.value === item.number;
            })
                .sort(function (item1, item2) {
                return state.value !== 'edit' && sortQuantity.value
                    ? item2.quantitySelected - item1.quantitySelected
                    : 0;
            });
        });
        var _h = transportItems_1.default(), tItems = _h.transportItems, loadTransportItems = _h.loadTransportItems, refreshTransportItems = _h.refreshTransportItems;
        var _j = orderTransportItems_1.default(), orderTransportItems = _j.orderTransportItems, loadOrderTransportItems = _j.loadOrderTransportItems, updateOrderTransportItem = _j.updateOrderTransportItem, refreshOrderTransportItems = _j.refreshOrderTransportItems;
        var transportItems = vue_1.computed(function () {
            console.log('compute transportItems');
            if (!orderTransportItems[orderID])
                return [];
            var i = tItems.data.map(function (tItem) {
                var _a;
                var orderTransportItemQuantity = ((_a = orderTransportItems[orderID].find(function (orderTransportItem) {
                    return orderTransportItem.id === tItem.id;
                })) === null || _a === void 0 ? void 0 : _a.quantity) || 0;
                return Object.assign(tItem, {
                    extra_numbers: [],
                    extra_descriptions: [],
                    quantityAvailable: null,
                    quantitySelected: orderTransportItemQuantity,
                    quantitySelectedBefore: orderTransportItemQuantity,
                });
            });
            console.log({ transportItems: i });
            return i;
        });
        refreshTransportItems();
        loadTransportItems()
            .then(function () { return refreshTransportItems(); })
            .catch(handleError_1.default);
        refreshOrderTransportItems(orderID);
        loadOrderTransportItems(orderID)
            .then(function (success) { return success && refreshOrderTransportItems(orderID); })
            .catch(handleError_1.default);
        ///
        var schedule = vue_1.reactive({ date: '', time: '' });
        refreshOrder(orderID);
        loadOrder(orderID)
            .then(function (success) { return success && refreshOrder(orderID); })
            .catch(handleError_1.default);
        refreshOrderItems(orderID); // better performance less accuracy at start
        loadOrderItems(orderID)
            .then(function (success) { return success && refreshOrderItems(orderID); })
            .catch(handleError_1.default);
        refreshLocationItems((_c = (_b = order.value) === null || _b === void 0 ? void 0 : _b.from) === null || _c === void 0 ? void 0 : _c.id); // better performance less accuracy at start
        vue_1.watch(order, function () {
            var _a, _b;
            console.log('compute ... (location items)');
            if (!((_b = (_a = order.value) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.id))
                return;
            console.log('compute location items');
            if (order.value.loaded_at) {
                state.value = 'view';
                onlySelected.value = true;
                localStorage.removeItem("unsavedItems" + orderID);
            }
            var date = order.value.scheduled_at.length > 0
                ? new Date(order.value.scheduled_at[order.value.scheduled_at.length - 1].scheduled_at)
                : new Date();
            schedule.date = new Intl.DateTimeFormat(language, {
                year: 'numeric',
            }).format(date) + "-" + new Intl.DateTimeFormat(language, {
                month: '2-digit',
            }).format(date) + "-" + new Intl.DateTimeFormat(language, {
                day: '2-digit',
            }).format(date);
            schedule.time = new Intl.DateTimeFormat(language, {
                hour: '2-digit',
            })
                .format(date)
                .slice(0, 2) + ":" + new Intl.DateTimeFormat(language, {
                minute: '2-digit',
            }).format(date);
            loadLocationItems(order.value.from.id)
                .then(function (success) {
                return success && refreshLocationItems(order.value.from.id);
            })
                .catch(handleError_1.default);
        });
        var onButton = {
            cancel: function () {
                if (state.value === 'check') {
                    items.value.forEach(function (item) {
                        item.checked = item.loaded;
                    });
                    state.value = 'edit';
                }
                else {
                    localStorage.removeItem("unsavedItems" + orderID);
                    router.back();
                }
            },
            save: function () {
                console.log('Save');
                var requests = [];
                for (var _i = 0, _a = items.value
                    .filter(function (item) {
                    return item.quantitySelected !==
                        item.quantitySelectedBefore;
                })
                    .map(function (item) {
                    return {
                        id: item.id,
                        quantity: item.quantitySelected,
                        quantityBefore: item.quantitySelectedBefore,
                    };
                }); _i < _a.length; _i++) {
                    var item = _a[_i];
                    console.log(item);
                    if (item.quantity === 0) {
                        requests.push(deleteOrderItem(orderID, {
                            id: item.id,
                        }));
                    }
                    else if (item.quantityBefore === 0) {
                        requests.push(createOrderItem(orderID, {
                            id: item.id,
                            quantity: item.quantity,
                        }));
                    }
                    else {
                        requests.push(updateOrderItem(orderID, {
                            id: item.id,
                            quantity: item.quantity,
                        }));
                    }
                }
                Promise.all(requests)
                    .then(function () {
                    localStorage.removeItem("unsavedItems" + orderID);
                    return loadLocationItems(order.value.from.id);
                })
                    // load order items again to have the latest values
                    // because it could be that the last promise to resolve was not the last one to be requested therefore not returning the most up to date information
                    .then(function () { return loadOrderItems(orderID); })
                    .then(function () {
                    refreshLocationItems(order.value.from.id);
                    refreshOrderItems(orderID);
                })
                    .catch(handleError_1.default);
            },
            saveLoading: function () {
                var requests = [];
                for (var _i = 0, _a = items.value
                    .filter(function (item) { return item.checked !== item.loaded; })
                    .map(function (item) {
                    return {
                        id: item.id,
                        checked: item.checked,
                    };
                }); _i < _a.length; _i++) {
                    var item = _a[_i];
                    console.log(item);
                    requests.push(updateOrderItem(orderID, {
                        id: item.id,
                        loaded: item.checked,
                    }));
                }
                Promise.all(requests)
                    .then(function () {
                    return loadLocationItems(order.value.from.id);
                })
                    // load order items again to have the latest values
                    // because it could be that the last promise to resolve was not the last one to be requested therefore not returning the most up to date information
                    .then(function () { return loadOrderItems(orderID); })
                    .then(function () {
                    refreshLocationItems(order.value.from.id);
                    refreshOrderItems(orderID);
                })
                    .catch(handleError_1.default);
            },
            load: function () {
                state.value = 'check';
                onlySelected.value = true;
            },
            loaded: function () {
                updateOrder(orderID, { state: 'loaded' })
                    .then(function () {
                    element_plus_1.ElMessage({
                        message: 'Beladen bestätigt',
                        duration: 2000,
                        type: 'success',
                    });
                    router.back();
                })
                    .catch(handleError_1.default);
            },
            delivered: function () {
                updateOrder(orderID, { state: 'delivered' })
                    .then(function () {
                    element_plus_1.ElMessage({
                        message: 'Lieferung bestätigt',
                        duration: 2000,
                        type: 'success',
                    });
                    router.back();
                })
                    .catch(handleError_1.default);
            },
            saveScheduled: function () {
                console.log('save Schedule');
                console.log({ date: schedule.date, time: schedule.time });
                updateOrder(orderID, {
                    scheduled: new Date(schedule.date + "T" + schedule.time).toISOString(),
                })
                    .then(function () {
                    element_plus_1.ElMessage({
                        message: 'Datum geändert',
                        duration: 2000,
                        type: 'success',
                    });
                    refreshOrder(orderID);
                })
                    .catch(handleError_1.default);
            },
            saveTransport: function () {
                console.log('save transport items');
                updateOrderTransportItem(orderID, {
                    orderTransportItems: transportItems.value.map(function (tItem) {
                        return {
                            id: tItem.id,
                            quantity: tItem.quantitySelected,
                        };
                    }),
                    to: order.value.transport_items_to_selected,
                })
                    .then(function () { return loadOrder(orderID); })
                    .then(function () {
                    element_plus_1.ElMessage({
                        message: 'Transport Artikel gepeichert',
                        duration: 2000,
                        type: 'success',
                    });
                    refreshOrder(orderID);
                    refreshOrderTransportItems(orderID);
                })
                    .catch(handleError_1.default);
            },
            saveNote: function () {
                updateOrder(orderID, {
                    note: note.value,
                })
                    .then(function () {
                    element_plus_1.ElMessage({
                        message: 'Notiz geändert',
                        duration: 2000,
                        type: 'success',
                    });
                    refreshOrder(orderID);
                })
                    .catch(handleError_1.default);
            },
        };
        var onInputChange = function () {
            var unsavedItems = items.value.filter(function (item) { return item.quantitySelected !== item.quantitySelectedBefore; });
            localStorage.setItem("unsavedItems" + orderID, JSON.stringify(unsavedItems.map(function (item) { return ({
                id: item.id,
                quantity: item.quantitySelected,
            }); })));
        };
        // let date = ref(
        //     order.value && order.value.scheduled_at[0]
        //         ? order.value.scheduled_at[0].scheduled_at
        //         : new Date()
        // );
        return {
            items: items,
            itemsFiltered: itemsFiltered,
            category: category,
            group: group,
            transportItems: transportItems,
            order: order,
            orderItems: orderItems,
            search: search,
            state: state,
            onlySelected: onlySelected,
            sortQuantity: sortQuantity,
            loading: loading,
            user: user,
            dateTimeSelectorVisible: vue_1.ref(false),
            language: language,
            schedule: schedule,
            transportItemsDialog: transportItemsDialog,
            noteDialog: noteDialog,
            note: note,
            onButton: onButton,
            onInputChange: onInputChange,
        };
    },
};
