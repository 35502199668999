<template>
    <div>
        <h1>404 Not Found</h1>
    </div>
</template>

<script>
export default {
    name: "PageNotFound",
};
</script>

<style></style>
