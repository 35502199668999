"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routes = void 0;
var vue_router_1 = require("vue-router");
var Home_vue_1 = require("./pages/Home.vue");
var Login_vue_1 = require("./pages/Login.vue");
var EditOrder_vue_1 = require("./pages/EditOrder.vue");
var ViewOrders_vue_1 = require("./pages/ViewOrders.vue");
var Locations_vue_1 = require("./pages/Locations.vue");
var LocationOverview_vue_1 = require("./pages/LocationOverview.vue");
var LocationItems_vue_1 = require("./pages/LocationItems.vue");
var ItemLocations_vue_1 = require("./pages/ItemLocations.vue");
var User_vue_1 = require("./pages/User.vue");
var ViewItems_vue_1 = require("./pages/ViewItems.vue");
var _404_vue_1 = require("./pages/404.vue");
exports.routes = [
    {
        name: 'home',
        path: '/',
        component: Home_vue_1.default,
    },
    {
        name: 'login',
        path: '/login',
        component: Login_vue_1.default,
    },
    {
        name: 'orders',
        path: '/orders',
        component: ViewOrders_vue_1.default,
    },
    {
        name: 'orders.edit',
        path: '/orders/:id/edit',
        component: EditOrder_vue_1.default,
    },
    {
        name: 'user',
        path: '/user',
        component: User_vue_1.default,
    },
    {
        name: 'items',
        path: '/items',
        component: ViewItems_vue_1.default,
    },
    {
        name: 'locations',
        path: '/locations',
        component: Locations_vue_1.default,
    },
    {
        name: 'locations.overview',
        path: '/locations/overview',
        component: LocationOverview_vue_1.default,
    },
    {
        name: 'locations.items',
        path: '/locations/:id/items',
        component: LocationItems_vue_1.default,
    },
    {
        name: 'items.locations',
        path: '/items/:id/locations',
        component: ItemLocations_vue_1.default,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: _404_vue_1.default,
    },
];
var router = vue_router_1.createRouter({
    history: vue_router_1.createWebHistory(),
    routes: exports.routes,
});
exports.default = router;
