"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.padNumber = exports.itemInGroupOrCategory = exports.includesCategory = exports.searchIncludesItem = exports.toEUR = void 0;
var toEUR = function (value) {
    return (value / 100).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
};
exports.toEUR = toEUR;
var searchIncludesItem = function (search, item) {
    search = search.trim().toLowerCase();
    return (!search ||
        item.number.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search) ||
        item.extra_numbers.some(function (number) {
            return number.toLowerCase().includes(search);
        }) ||
        item.extra_descriptions.some(function (description) {
            return description.toLowerCase().includes(search);
        }));
};
exports.searchIncludesItem = searchIncludesItem;
var includesCategory = function (category, categories) {
    return (category === 'all' ||
        (category === 'other' && categories.length === 0) ||
        categories.includes(category));
};
exports.includesCategory = includesCategory;
var itemInGroupOrCategory = function (category, group, item, groups) {
    var _a, _b;
    var itemCategories = item.categories;
    var groupCategories = (_b = (_a = groups.find(function (g) { return g.id === item.group; })) === null || _a === void 0 ? void 0 : _a.categories) !== null && _b !== void 0 ? _b : [];
    var combinedCategories = itemCategories.concat(groupCategories);
    if (group === item.group)
        return true;
    if (group === 'all' && exports.includesCategory(category, combinedCategories))
        return true;
    if (group === 'other') {
        if (category === 'other') {
            if (exports.includesCategory(category, itemCategories) &&
                exports.includesCategory(category, groupCategories))
                return true;
        }
        else {
            if (exports.includesCategory(category, itemCategories) &&
                !exports.includesCategory(category, groupCategories))
                return true;
        }
    }
};
exports.itemInGroupOrCategory = itemInGroupOrCategory;
var padNumber = function (number, length) {
    return number.toString().padStart(Math.floor(Math.log10(length)) + 1, ' ');
};
exports.padNumber = padNumber;
