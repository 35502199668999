"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var items_1 = require("../composables/items");
var handleError_1 = require("../utils/handleError");
var groups_1 = require("../composables/groups");
var functions_1 = require("../utils/functions");
var ItemView_vue_1 = require("../components/ItemView.vue");
var CategorySelect_vue_1 = require("../components/CategorySelect.vue");
var GroupSelect_vue_1 = require("../components/GroupSelect.vue");
var router_1 = require("../router");
var users_1 = require("../composables/users");
exports.default = {
    components: { ItemView: ItemView_vue_1.default, CategorySelect: CategorySelect_vue_1.default, GroupSelect: GroupSelect_vue_1.default },
    setup: function () {
        var search = vue_1.ref('');
        var onlySelected = vue_1.ref(false);
        var loading = vue_1.ref(true);
        var user = users_1.default().user;
        var _a = items_1.default(), items = _a.items, loadItems = _a.loadItems, refreshItems = _a.refreshItems;
        refreshItems();
        loadItems()
            .then(function () { return refreshItems(); })
            .catch(handleError_1.default);
        var category = vue_1.ref('all');
        var group = vue_1.ref('all');
        var _b = groups_1.default(), groups = _b.groups, loadGroups = _b.loadGroups, refreshGroups = _b.refreshGroups;
        refreshGroups();
        loadGroups()
            .then(function () {
            refreshGroups();
        })
            .catch(handleError_1.default);
        var itemsFiltered = vue_1.computed(function () {
            return items.data.filter(function (item) {
                return (functions_1.itemInGroupOrCategory(category.value, group.value, item, groups.data) &&
                    functions_1.searchIncludesItem(search.value, item)) ||
                    search.value === item.number;
            });
        });
        var goToItemLocations = function (id) {
            router_1.default.push({ name: 'items.locations', params: { id: id } });
        };
        return {
            items: items,
            itemsFiltered: itemsFiltered,
            user: user,
            category: category,
            group: group,
            search: search,
            onlySelected: onlySelected,
            loading: loading,
            goToItemLocations: goToItemLocations,
        };
    },
};
