<template>
    <div class="container flex">
        <el-form
            label-width="120px"
            :model="loginForm"
            :rules="loginForm.rules"
            class="max-w-md"
        >
            <el-form-item label="Benutzername" prop="name">
                <el-input v-model="loginForm.name"></el-input>
            </el-form-item>
            <el-form-item label="Passwort" prop="password">
                <el-input
                    v-model="loginForm.password"
                    type="password"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    @click.prevent="onLogin"
                    native-type="submit"
                >
                    Anmelden
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { reactive } from 'vue';
import handleError from '../utils/handleError';
import useUsers from '../composables/users';

export default {
    setup() {
        const { loadUser, refreshUser } = useUsers();

        const loginForm = reactive({
            name: '',
            password: '',
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Bitte gebe einen Benutzernamen an',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Bitte gebe ein Passwort an',
                        trigger: 'blur',
                    },
                ],
            },
        });

        const onLogin = () => {
            axios.get('/sanctum/csrf-cookie').then(() => {
                axios
                    .post('/api/sessions', {
                        name: loginForm.name,
                        password: loginForm.password,
                    })
                    .then(() => loadUser())
                    .then(() => {
                        refreshUser();
                        router.push({ name: 'home' });
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            ElMessage.error({
                                message: 'Benutzername oder Passwort inkorrekt',
                                showClose: true,
                            });
                            loginForm.password = '';
                        } else if (error.response.status === 422) {
                            ElMessage.error({
                                message:
                                    'Bitte gebe Benutzername und Password an',
                                showClose: true,
                            });
                        } else {
                            handleError(error);
                        }
                    });
            });
        };

        return {
            loginForm,
            onLogin,
        };
    },
};
</script>

<style scoped></style>
