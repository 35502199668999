"use strict";
// window._ = require("lodash");
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var handleError_1 = require("./vue/utils/handleError");
window.dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};
axios_1.default.defaults.withCredentials = true;
axios_1.default.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios_1.default.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    if (error.response.status === 401 &&
        !(error.config.url === '/api/sessions' &&
            error.config.method === 'post')) {
        window.router.push({ name: 'login' });
        return;
    }
    if (error.response.status === 403 &&
        window.router.currentRoute.value.name === 'orders.edit') {
        window.router.replace({ name: 'orders' });
        return;
    }
    if (error.response.status === 419) {
        return axios_1.default
            .get('/sanctum/csrf-cookie')
            .then(function () { return axios_1.default.request(error.config); })
            .catch(handleError_1.default);
    }
    return Promise.reject(error);
});
window.axios = axios_1.default;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
