<template>
    <el-select
        v-model="modelValue"
        placeholder="Gruppe"
        size="small"
        v-if="groupsFiltered && groupsFiltered.length > 1"
        @change="$emit('update:modelValue', modelValue)"
    >
        <el-option
            v-for="(group, index) in groupsFiltered"
            :key="group.id"
            :label="
                (['all', 'other'].includes(group.id)
                    ? ''
                    : `${padNumber(
                          index + 1,
                          groupsFiltered.filter(
                              (c) => !['all', 'others'].includes(c.id)
                          ).length - 1
                      )}. `) + group.name
            "
            :value="group.id"
        >
        </el-option>
    </el-select>
</template>

<script>
import { computed } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';

import useGroups from '../composables/groups';
import { padNumber } from '../utils/functions';
import handleError from '../utils/handleError';

export default {
    props: {
        modelValue: String,
        category: String,
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const { groups, loadGroups, refreshGroups } = useGroups();

        loadGroups()
            .then(() => {
                refreshGroups();
            })
            .catch(handleError);

        const groupsFiltered = computed(() => {
            return groups.data.filter(
                (group) =>
                    // props.category === 'all' &&  ||
                    (group.id === 'other' && props.category !== 'all') ||
                    // ['all', 'other'].includes(group.id) ||
                    group.categories.includes(props.category)
            );
        });

        watch(groupsFiltered, () => {
            if (
                props.modelValue === 'other' ||
                !groupsFiltered.value.find(
                    (group) => group.id === props.modelValue
                )
            ) {
                context.emit('update:modelValue', groupsFiltered.value[0].id);
            }
        });

        return { groups, groupsFiltered, padNumber };
    },
};
</script>

<style></style>
